import React from "react";
import { graphql, PageProps } from "gatsby";

import Hero from "@shared/hero";
import SEO from "@shared/seo";
import { Query, SanityHomeConnection } from "@graphql-types";
import {
  isSanityBlockContent,
  isSanityCarouselWithButton,
  isSanityCarouselWithParagraph,
  isSanityFullWidthImageBlock,
  isSanityLeftRightBlock,
  isSanityFeatureBlockArray,
} from "@util/types";
import {
  CarouselWithButton,
  CarouselWithParagraph,
  FullWithImageBlock,
  ParagraphBlock,
  LatestNewsBlock,
  LeftRightBlock,
  FeaturedBlock,
} from "@global";
import { useRegionChanged } from "@util/hooks";

interface Data extends Query {
  altRegions: SanityHomeConnection;
}
interface Props extends PageProps {
  data: Data;
  pageContext: { iban: string };
}

export default function IndexPage({ data, pageContext }: Props) {
  const { nodes } = data.allSanityHome;

  const { homeHero, seo, components, latestNewsBlock } = nodes[0];
  const alternateSlugs = data.altRegions.nodes.map((alt) => alt.region);
  const contextIban = pageContext.iban;
  useRegionChanged("", contextIban);

  return (
    <div>
      <SEO seoData={seo} alternateRegions={alternateSlugs} heroData={homeHero} />
      <Hero heroData={homeHero} isHomepage />
      {components &&
        components.map((data) => {
          if (isSanityLeftRightBlock(data)) {
            return <LeftRightBlock data={data} key={data?._key} />;
          }
          if (isSanityFullWidthImageBlock(data)) {
            return <FullWithImageBlock data={data} key={data?._key} />;
          }
          if (isSanityCarouselWithParagraph(data)) {
            return <CarouselWithParagraph data={data} key={data?._key} />;
          }
          if (isSanityCarouselWithButton(data)) {
            return <CarouselWithButton data={data} key={data?._key} />;
          }
          if (isSanityBlockContent(data)) {
            return <ParagraphBlock data={data} key={data?._key} />;
          }
          if (isSanityFeatureBlockArray(data)) {
            return <FeaturedBlock data={data} key={data?._key} />;
          }
          return null;
        })}
      {latestNewsBlock?.displayToggle && <LatestNewsBlock data={latestNewsBlock} />}
    </div>
  );
}

export const query = graphql`
  query HomePageQuery($iban: String) {
    allSanityHome(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        homeHero {
          ...sanityHero
        }
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        components {
          ... on SanityLeftRightBlock {
            ...sanityLeftRightBlock
          }
          ... on SanityFullWidthImageBlock {
            ...sanityFullWidthImageBlock
          }
          ... on SanityCarouselWithParagraph {
            ...sanityCarouselWithParagraph
          }

          ... on SanityCarouselWithButton {
            ...sanityCarouselWithButton
          }
          ... on SanityBlockContent {
            ...sanityBlockContent
          }
          ... on SanityFeatureBlockArray {
            ...sanityFeatureBlockArray
          }
        }
        latestNewsBlock {
          ...sanityLatestNewsBlock
        }
      }
    }
    altRegions: allSanityHome {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
